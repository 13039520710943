<script setup lang="ts">
import { useRouteQuery } from '@vueuse/router'
import StoreLogo from '~/components/atoms/StoreLogo.vue'

const localePath = useLocalePath()
const router = useRouter()
const route = useRoute()
const drawers = useDrawers()
const { t } = useI18n()
const { ecommerce } = useAnalytics()
const { navigation: accountNav } = useAccount()
const { activeOrder } = useActiveOrder()
const {
  rootCollections,
  collectionTree,
} = useCollections()
const {
  el: headerRef,
  isHeaderVisible,
} = useUiHeader()

const isSearchPage = computed(() => ((route?.name as string) ?? '').includes('search'))
const isCheckoutPage = computed(() => ((route?.name as string) ?? '').includes('checkout'))

const loggedIn = computed(() => useAuth().loggedIn.value === true)

const searchQuery = useRouteQuery('q', '')
const searchQueryInput = ref(searchQuery.value)

async function handleCartClick() {
  drawers.value.cart.open = true
  ecommerce.viewCart()
}

function handleNavigationClick() {
  drawers.value.navigation.open = true
}

function submitSearch(event: Event) {
  event.preventDefault()
  router.push({
    path: localePath('/search'),
    query: { q: searchQueryInput.value },
  })
}

watch(isSearchPage, (isSearchPage) => {
  if (!isSearchPage)
    searchQueryInput.value = ''
})

// account menu
const accountMenuTrigger = ref<HTMLElement | null>(null)

// Pick the first 3 collections to show on tabs
// const currentRootCollectionId = ref<string>(mainCollections.value[0]?.id)
// const currentRootCollection = computed(() => collectionTree.value[currentRootCollectionId.value])

// function onMainCollectionClick(collectionId: string) {
//   currentRootCollectionId.value = collectionId
// }

// https://github.com/tailwindlabs/headlessui/blob/f66f4926c489fc15289d528294c23a3dc2aee7b1/packages/%40headlessui-vue/src/components/menu/menu.ts#L131
const menuApi = ref<any>(null)
onMounted(() => {
  setTimeout(() => {
    // @ts-expect-error internals
    const menuProvides = accountMenuTrigger.value?.$.provides
    if (!menuProvides)
      return

    const menuProvidesSymbols = Object.getOwnPropertySymbols(menuProvides)
    menuApi.value = menuProvidesSymbols.length && menuProvides[menuProvidesSymbols[0]]
  }, 200)
})

let openTimeout: NodeJS.Timeout | null = null
let closeTimeout: NodeJS.Timeout | null = null

function onMouseOver() {
  if (!loggedIn.value || !menuApi.value)
    return

  // cancel programmed closing
  if (closeTimeout) {
    clearTimeout(closeTimeout)
    closeTimeout = null
  }
  // dropdown already open
  if (menuApi.value.menuState === 0)
    return

  openTimeout = openTimeout || setTimeout(() => {
    menuApi.value.openMenu && menuApi.value.openMenu()
    openTimeout = null
  }, 0)
}

function onMouseLeave() {
  if (!loggedIn.value || !menuApi.value)
    return

  // cancel programmed opening
  if (openTimeout) {
    clearTimeout(openTimeout)
    openTimeout = null
  }
  // dropdown already closed
  if (menuApi.value.menuState === 1)
    return

  closeTimeout = closeTimeout || setTimeout(() => {
    menuApi.value.closeMenu && menuApi.value.closeMenu()
    closeTimeout = null
  }, 100)
}

onUnmounted(() => {
  if (openTimeout)
    clearTimeout(openTimeout)

  if (closeTimeout)
    clearTimeout(closeTimeout)
})
</script>

<template>
  <Header ref="headerRef" :visible="isHeaderVisible">
    <template #logo>
      <div class="flex">
        <NButton
          id="navigation"
          n="xl borderless"
          icon="i-fluent:navigation-24-regular"
          class="p-0.25em children:m-0"
          :aria-label="t('general.navigation.label')"
          @click="handleNavigationClick"
        />

        <div class="my-auto ml0 mr-auto flex items-center gap-4">
          <NuxtLinkLocale
            to="/" class="ml2 mr3 w20 flex overflow-hidden md:w-auto xs:w-[8.75rem]"
            :aria-label="t('general.homepage.label')"
          >
            <StoreLogo class="h9 w-auto text-slate-900 dark:text-white" />
          </NuxtLinkLocale>

          <NuxtLinkLocale to="/collections/best-sellers" class="hidden text-sm text-black uppercase -ml2 md:block">
            {{ t('pages.title.best_sellers') }}
          </NuxtLinkLocale>
          <NuxtLinkLocale to="/collections/new-arrivals" class="hidden text-sm text-black uppercase md:block">
            {{ t('pages.title.new_arrivals') }}
          </NuxtLinkLocale>
        </div>
      </div>
    </template>

    <!-- <template #center>

     </template>
 -->
    <template #right>
      <!--          <NButton n="xl" icon="i-heroicons:heart" class="p-0.25em children:m-0 border-0 shadow-none" /> -->
      <!--  <NButton
id="support"
:aria-label="t('general.support.label')"
n="xl" icon="i-heroicons:question-mark-circle" class="border-0 p-0.25em shadow-none children:m-0" @click="openMessenger"
/> -->
      <form class="mx2 hidden bg-gray-200 md:block" @submit.prevent="submitSearch">
        <label for="searchbar" class="sr-only bg-gray-200">{{ $t('general.search.label') }}</label>
        <NTextInput id="searchbar" v-model="searchQueryInput" icon="i-fluent:search-24-regular" />
      </form>
      <NButton
        id="account"
        n="xl borderless"
        icon="i-fluent:person-24-regular"
        class="p0.25em children:m0 sm:hidden"
        to="/account"
        :aria-label="t('page.account.title')"
      />

      <div class="hidden text-right sm:flex">
        <HMenu
          v-slot="{ open }"
          as="div"
          class="relative inline-block text-left"
          @mouseleave="onMouseLeave"
        >
          <div />

          <div v-if="open && loggedIn" @mouseover="onMouseOver">
            <Transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 op0"
              enter-to-class="transform scale-100 op100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 op100"
              leave-to-class="transform scale-95 op0"
            >
              <HMenuItems
                class="absolute right-0 mt2 w56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 divide-y divide-slate-100 focus:outline-none"
              >
                <div v-for="accountNavItem in accountNav" :key="accountNavItem.label" class="p1">
                  <HMenuItem
                    v-slot="{ close }" class="cursor-pointer"
                    @click="router.push(localePath(accountNavItem.to))"
                  >
                    <div
                      class="group w-full flex items-center rounded-md p2 text-sm text-slate-600 fw600 hover:bg-slate-50 hover:text-slate-900"
                    >
                      <NIcon :icon="accountNavItem.icon" class="n-button-icon" />
                      <NuxtLinkLocale
                        :to="accountNavItem.to"
                        @click="() => {
                          close()
                          accountNavItem.onClick?.()
                        }"
                      >
                        {{ accountNavItem.label }}
                      </NuxtLinkLocale>
                    </div>
                  </HMenuItem>
                </div>
              </HMenuItems>
            </Transition>
          </div>
        </HMenu>
      </div>

      <div class="relative ml2 flex items-center rounded-md bg-black px-4">
        <NButton
          id="cart"
          n="xl borderless"
          icon="i-mingcute:shopping-cart-1-line"
          class="p0.25em text-white children:m0"
          :aria-label="t('cart.label')"
          @click="handleCartClick"
        />

        <ClientOnly>
          <span
            v-if="activeOrder && activeOrder?.totalQuantity > 0"
            class="pointer-events-none absolute h6 w6 flex items-center justify-center rounded-full bg-white text-context -right-2 -top-1"
            n="sm black"
          >
            {{ activeOrder.totalQuantity }}
          </span>
        </ClientOnly>
      </div>
    </template>

    <template v-if="!isCheckoutPage" #navbar>
      <div class="flex flex-row items-center gap-x-2 lg:-m-x-1">
        <div class="flex grow content-center items-center of-hidden lg:flex lg:px8 lg:py-2 lg:py4 lg:-ml6 lg:space-x-8">
          <template v-if="rootCollections?.length">
            <NuxtLinkLocale
              v-for="collection in rootCollections.slice(0, 9)"
              :key="collection.id"
              :to="`/collections/${collection.id}/${collection.slug}`"
              n="sm slate9 dark:slate2"
              class="whitespace-nowrap text-context fw-medium n-transition"
            >
              {{ collection.name }}
            </NuxtLinkLocale>
          </template>
          <template v-else>
            <NSkeleton v-for="i in 9" :key="i" :style="{ width: `${100 + (i * i + i) % 7 * 20}px` }" pulse />
          </template>
        </div>
      </div>
    </template>

    <!-- <template v-if="!isCheckoutPage" #search >
      <div class="w-full">
        <form class="bg-gray-200" @submit.prevent="submitSearch">
          <label for="search" class="sr-only">{{ t('general.search.label') }}</label>
          <NTextInput id="search" v-model="searchQueryInput" icon="i-heroicons:magnifying-glass" />
        </form>
      </div>
    </template> -->
  </Header>
</template>
